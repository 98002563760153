import {
  Border,
  CalloutCard,
  InViewElement,
  InViewElements,
  InViewElementsState,
  LocalImage,
} from "@reusable";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { InView } from "react-intersection-observer";
import meanCycle1 from "./mean-cycle-1-d.svg";
import meanCycle1Small from "./mean-cycle-1-m.svg";
import meanCycle2 from "./mean-cycle-2-d.svg";
import meanCycle2Small from "./mean-cycle-2-m.svg";
import meanDays from "./mean-dsn-days-desktop-1.svg";
import meanDaysSmall from "./mean-dsn-days-small-mobile.svg";
import meanDurationChartSmall from "./mean-duration-chart-small-mobile-4.svg";
import meanDurationChart from "./mean-duration-chart.svg";
import ProfileFigure from "./profile-figure";
import study1Small from "./study-1-small.svg";
import study1 from "./study-1.svg";
import study2Small from "./study-2-small.svg";
import study2 from "./study-2.svg";
import studyFebrileSmall from "./study-febrile-small.svg";
import studyFebrile from "./study-febrile.svg";
import studyLegend from "./study-legend.png";
import "./styles.scss";

export default function StudyDesign() {
  return (
    <InViewElements>
      <PageNavigationSmall />
      <div className="container">
        <div className="page-layout">
          <InViewElement id="duration-of-sn" className="page-layout-content">
            <div className="safety-heading-container animate-on-enter-2">
              <h2 className="page-layout-heading">
                Demonstrated noninferiority vs pegfilgrastim<sup>1-3</sup>
              </h2>
            </div>

            <Border />

            <section className="safety-mean-duration-container animate-on-enter-3">
              <h3 className="safety-heading-alt">
                <span className="safety-heading-lede">Primary endpoint</span>
                Mean Duration of Severe Neutropenia (Days) in Cycle 1
                <sup>1-3</sup>
              </h3>

              <img
                src={meanDurationChart}
                alt="Chart: Mean Duration of Severe Neutropenia"
                className="large-only"
                height="270"
              />
              <img
                src={meanDurationChartSmall}
                alt="Chart: Mean Duration of Severe Neutropenia"
                className="small-only"
              />

              <h3 style={{ fontSize: "1.25rem" }}>
                Selected Safety Information
              </h3>
              <h4>Adverse Reactions</h4>
              <ul>
                <li>
                  Permanent discontinuation due to an adverse reaction occurred
                  in 4% of patients who received ROLVEDON. The adverse reaction
                  requiring permanent discontinuation in 3 patients who received
                  ROLVEDON was rash.
                </li>
              </ul>
            </section>

            <Border alt />

            <section className="safety-avoid-neutropenia-container animate-on-enter-2">
              <h3 className="safety-avoid-neutropenia-heading">
                Every day you help a cancer patient avoid severe neutropenia is
                critical<sup>4</sup>
              </h3>

              <p>
                Incidence and duration of Severe Neutropenia (DSN) by Day in
                Cycle 1<sup>1-3</sup>
              </p>

              <img
                src={study1}
                alt="Chart: Incidence and duration of Severe Neutropenia, Study 1"
                className="large-only image-size imageDesktopFix"
                height="430"
              />

              <img
                src={study2}
                alt="Chart: Incidence and duration of Severe Neutropenia, Study 2"
                className="large-only image-size imageDesktopFix"
                height="430"
              />

              {/* <div className="large-only">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "-5rem",
                    marginBottom: "2rem",
                  }}
                >
                  <img
                    src={studyLegend}
                    style={{
                      maxWidth: "16rem",
                    }}
                    height="18"
                    alt="Legend visual for study design chart"
                  />
                </div>
              </div> */}

              <div className="small-only">
                <div className="safety-avoid-study-images-small">
                  <img
                    src={study1Small}
                    alt="Chart: Incidence and duration of Severe Neutropenia, Study 1"
                  />

                  <p>
                    ~84% of ROLVEDON patients had no severe neutropenia
                    (compared to ~76% of pegfilgrastim patients)<sup>1,2</sup>
                  </p>

                  <img
                    src={study2Small}
                    alt="Chart: Incidence and duration of Severe Neutropenia, Study 2"
                  />

                  <p>
                    ~80% of ROLVEDON patients had no severe neutropenia
                    (compared to ~77% of pegfilgrastim patients)<sup>2,3</sup>
                  </p>

                  <img
                    src={studyLegend}
                    alt="Legend visual for study design chart"
                    height="18"
                  />
                </div>
              </div>

              <ul>
                <li>
                  <strong>
                    Comparisons were conducted on patients with no SN and on
                    individual days. The data do not support any{" "}
                    <span className="do-not-break-text">day-to-day</span>{" "}
                    comparison advantages
                  </strong>
                </li>
                <li>
                  <strong>
                    Demonstrated noninferiority vs pegfilgrastim<sup>1-3</sup>
                  </strong>
                </li>
              </ul>

              <h2>Selected Safety Information</h2>
              <h3>Adverse Reactions</h3>
              <ul>
                <li>
                  The most common adverse reactions (≥20%) were fatigue, nausea,
                  diarrhea, bone pain, headache, pyrexia, anemia, rash, myalgia,
                  arthralgia, and back pain.
                </li>
              </ul>
            </section>
          </InViewElement>
          <div
            className="page-layout-aside animate-on-enter-3"
            style={{
              position: "static",
            }}
          >
            <div>
              <div className="large-only">
                <ReviewCallout />
              </div>

              <PageNavigation />
            </div>
          </div>
        </div>
      </div>

      <InViewElement id="anc-profile">
        <section className="full-callout">
          <div className="container">
            <div className="full-callout-inner-container women animate-on-enter-2">
              <LocalImage
                name="person-1"
                className="large-only"
                alt="a woman with high risk of chemotherapy induced neutropenia"
              />

              <LocalImage
                name="person-1-small"
                className="small-only"
                alt="a woman with high risk of chemotherapy induced neutropenia"
              />

              <p className="full-callout-text-ANC">
                ANC profile vs pegfilgrastim <sup>1,2,5</sup>
              </p>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="page-layout">
            <div className="page-layout-content">
              <ProfileFigure />

              <Border />

              <section className="safety-mean-cycle-charts">
                <h3 className="safety-heading-alt">
                  Mean ANC Over Time in Cycle 1<sup>1,3</sup>
                </h3>

                <figure>
                  <img
                    src={meanCycle1}
                    className="large-only"
                    alt="Chart: Mean ANC Over Time in Cycle in Study 1"
                    height="320"
                  />
                  <img
                    src={meanCycle1Small}
                    className="small-only"
                    alt="Chart: Mean ANC Over Time in Cycle in Study 1"
                  />

                  <div style={{ margin: "2rem 0" }}>
                    <img
                      src={meanCycle2}
                      className="large-only"
                      alt="Chart: Mean ANC Over Time in Cycle in Study 2"
                      height="320"
                    />
                    <img
                      src={meanCycle2Small}
                      className="small-only"
                      alt="Chart: Mean ANC Over Time in Cycle in Study 2"
                    />
                  </div>

                  <figcaption>
                    <ul>
                      <li>
                        <strong>
                          All data supporting endpoints were contained within
                          days 5 through 8
                        </strong>
                      </li>
                      <li className="hide-list-style" style={{
                fontSize: "0.85rem",
              }}>
                        ANC=absolute neutrophil count; SD=standard deviation.
                      </li>
                    </ul>
                  </figcaption>
                </figure>

                <h2
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  Selected Safety Information
                </h2>

                <h3
                  style={{
                    marginBottom: "0",
                  }}
                >
                  Leukocytosis
                </h3>

                <ul>
                  <li>
                    White blood cell (WBC) counts of 100 x 10
                    <sup class="supUP">9</sup>/L or greater have been observed
                    in patients receiving rhG-CSF products. Monitor complete
                    blood count (CBC) during ROLVEDON therapy. Discontinue
                    ROLVEDON treatment if WBC count of 100 x 10
                    <sup class="supUP">9</sup>/L or greater occurs.
                  </li>
                </ul>
              </section>

              <Border alt />
            </div>
          </div>
        </div>
      </InViewElement>

      <InViewElement id="incidence-of-fn">
        <section className="full-callout">
          <div className="container">
            <div className="full-callout-inner-container">
              <LocalImage
                name="person-2"
                className="full-callout-image large-only"
                alt="a man with febrile neutropenia"
              />

              <LocalImage
                name="person-2-small"
                className="full-callout-image small-only"
                alt="a man with febrile neutropenia"
              />

              <p className="full-callout-text">
                <span
                  style={{
                    display: "block",
                    fontSize: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Secondary endpoint
                </span>
                Incidence of febrile neutropenia in cycle 1<sup>1,3</sup>
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="page-layout">
            <div className="page-layout-content">
              <p>
                From a total of 643 patients in both studies, febrile
                neutropenia was observed in 11 patients in cycle 1.
                <sup>1,3</sup>
              </p>

              <div
                style={{
                  margin: "2rem 0",
                }}
              >
                <img
                  src={studyFebrile}
                  alt="Chart: The incidence of febrile neutropenia"
                  className="large-only"
                  height="200"
                />
                <img
                  src={studyFebrileSmall}
                  alt="Chart: The incidence of febrile neutropenia"
                  className="small-only"
                />
              </div>

              <ul>
                <li style={{ fontWeight: "700" }}>
                  The incidence of febrile neutropenia in cycle 1 was a
                  secondary endpoint, the data were insufficient to support
                  statistical analysis.
                </li>
              </ul>

              <h2>Selected Safety Information</h2>

              <h3>Sickle Cell Crisis in Patients with Sickle Cell Disorders</h3>

              <ul>
                <li>
                  Severe and sometimes fatal sickle cell crises can occur in
                  patients with sickle cell disorders receiving rhG-CSF
                  products, such as ROLVEDON. Discontinue ROLVEDON if sickle
                  cell crisis occurs.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </InViewElement>

      <InViewElement id="cycles-2to4">
        <section className="full-callout">
          <div className="container">
            <div className="full-callout-inner-container">
              <LocalImage
                name="person-3"
                className="full-callout-image large-only"
                alt="a woman who recently underwent chemotherapy"
              />
              <LocalImage
                name="person-3-small"
                className="full-callout-image small-only"
                alt="a woman who recently underwent chemotherapy"
              />

              <p className="full-callout-text">
                Efficacy across 4 cycles of chemotherapy<sup>1,2,5</sup>
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="page-layout">
            <div className="page-layout-content safety-mean-days">
              <h3 className="safety-heading-alt">
                <span className="safety-heading-lede--purple">
                  Additional secondary endpoint
                </span>
                Mean DSN (Days) in Cycles 2 to 4<sup>1,2,5</sup>
              </h3>

              <figure>
                <div>
                  <img
                    src={meanDays}
                    alt="Chart: Mean DSN (Days) in Cycles 2 to 4"
                    className="large-only"
                    height="387"
                  />
                  <img
                    src={meanDaysSmall}
                    alt="Chart: Mean DSN (Days) in Cycles 2 to 4"
                    className="small-only"
                  />
                </div>
                <figcaption
                  style={{
                    margin: "2rem 0",
                    fontWeight: "600",
                  }}
                >
                  Mean DSN values for cycles 2 to 4 are secondary endpoints with
                  demonstrated noninferiority between treatment arms.
                </figcaption>
              </figure>

              <h2>Selected Safety Information</h2>

              <h3>Splenic Rupture</h3>

              <ul>
                <li>
                  Splenic rupture, including fatal cases, can occur following
                  the administration of recombinant human granulocyte
                  colony-stimulating factor (rhG-CSF) products. Evaluate
                  patients who report left upper abdominal or shoulder pain for
                  an enlarged spleen or splenic rupture.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </InViewElement>

      <InViewElement id="safety-profile">
        <section className="full-callout">
          <div className="container">
            <div className="full-callout-inner-container">
              <LocalImage
                name="person-4"
                className="full-callout-image large-only"
                alt="a woman who was recently diagnosed with early-stage breast cancer"
              />
              <LocalImage
                name="person-4-small"
                className="full-callout-image small-only"
                alt="a woman who was recently diagnosed with early-stage breast cancer"
              />

              <p className="full-callout-text">
                Proven safety profile<sup>2</sup>
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="page-layout">
            <div className="page-layout-content">
              <h3
                className="safety-heading-alt"
                style={{
                  marginTop: "2rem",
                }}
              >
                Common Adverse Reactions with a Frequency of ≥10%
                Through Week 14 in Patients with Early-Stage Breast Cancer in
                Study 1 and Study 2
              </h3>

              <figure className="safety-reaction-figure">
                <div
                  style={{
                    overflowX: "auto",
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Adverse Reaction</th>
                        <th>ROLVEDON (n=314)%</th>
                        <th>Pegfilgrastim** (n=326)%</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Fatigue*</td>
                        <td>181 (58%)</td>
                        <td>192 (59%)</td>
                      </tr>
                      <tr>
                        <td>Nausea</td>
                        <td>162 (52%)</td>
                        <td>166 (51%)</td>
                      </tr>
                      <tr>
                        <td>Diarrhea</td>
                        <td>125 (40%)</td>
                        <td>126 (39%)</td>
                      </tr>
                      <tr>
                        <td>Bone pain</td>
                        <td>119 (38%)</td>
                        <td>121 (37%)</td>
                      </tr>
                      <tr>
                        <td>Headache*</td>
                        <td>92 (29%)</td>
                        <td>90 (28%)</td>
                      </tr>
                      <tr>
                        <td>Pyrexia*</td>
                        <td>87 (28%)</td>
                        <td>84 (26%)</td>
                      </tr>
                      <tr>
                        <td>Anemia*</td>
                        <td>77 (25%)</td>
                        <td>52 (16%)</td>
                      </tr>
                      <tr>
                        <td>Rash*</td>
                        <td>77 (25%)</td>
                        <td>99 (30%)</td>
                      </tr>
                      <tr>
                        <td>Myalgia</td>
                        <td>69 (22%)</td>
                        <td>49 (15%)</td>
                      </tr>
                      <tr>
                        <td>Arthralgia</td>
                        <td>66 (21%)</td>
                        <td>48 (15%)</td>
                      </tr>
                      <tr>
                        <td>Back pain*</td>
                        <td>63 (20%)</td>
                        <td>55 (17%)</td>
                      </tr>
                      <tr>
                        <td>Decreased appetite</td>
                        <td>61 (19%)</td>
                        <td>50 (15%)</td>
                      </tr>
                      <tr>
                        <td>Peripheral edema*</td>
                        <td>57 (18%)</td>
                        <td>53 (16%)</td>
                      </tr>
                      <tr>
                        <td>Abdominal pain*</td>
                        <td>53 (17%)</td>
                        <td>67 (21%)</td>
                      </tr>
                      <tr>
                        <td>Dizziness*</td>
                        <td>50 (16%)</td>
                        <td>38 (12%)</td>
                      </tr>
                      <tr>
                        <td>Dyspnea*</td>
                        <td>49 (16%)</td>
                        <td>44 (13%)</td>
                      </tr>

                      <tr>
                        <td>Cough*</td>
                        <td>48 (15%)</td>
                        <td>51 (16%)</td>
                      </tr>
                      <tr>
                        <td>Thrombocytopenia*</td>
                        <td>44 (14%)</td>
                        <td>17 (5%)</td>
                      </tr>

                      <tr>
                        <td>Pain</td>
                        <td>37 (12%)</td>
                        <td>42 (13%)</td>
                      </tr>
                      <tr>
                        <td>Pain in extremity</td>
                        <td>36 (11%)</td>
                        <td>42 (13%)</td>
                      </tr>
                      <tr>
                        <td>Local administration reactions*</td>
                        <td>34 (11%)</td>
                        <td>27 (8%)</td>
                      </tr>
                      <tr>
                        <td>Flushing</td>
                        <td>32 (10%)</td>
                        <td>27 (8%)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </figure>

              <p style={{
                fontSize: "0.85rem",
              }}>
                *Grouped Terms <br />
                **Study 1 and Study 2 were not designed to evaluate meaningful
                comparisons of the incidence of adverse reactions in the
                ROLVEDON and the pegfilgrastim treatment groups.
              </p>

              <section className="references">
                <h2>References:</h2>

                <ol>
                  <li>
                    Schwartzberg LS, Bhat G, Peguero J, et al. Eflapegrastim, a
                    long-acting granulocyte-colony stimulating factor for the
                    management of chemotherapy-induced neutropenia: results of a
                    phase III trial. <em>Oncologist.</em>{" "}
                    2020;25(8):e1233-e1241.
                  </li>
                  <li>
                    ROLVEDON [package insert]. Lake Forest, IL: Spectrum
                    Pharmaceuticals, Inc.
                  </li>
                  <li>
                    Cobb PW, Moon YW, Mezei K, et al. A comparison of
                    eflapegrastim to pegfilgrastim in the management of
                    chemotherapy-induced neutropenia in patients with
                    early-stage breast cancer undergoing cytotoxic chemotherapy
                    (RECOVER): a phase 3 study. <em>Cancer Med.</em>{" "}
                    2020;9(17):6234–6243.
                  </li>
                  <li>
                    Li Y, Klippel Z, Shih X, Reiner M, Wang H, Page JH.
                    Relationship between severity and duration of
                    chemotherapy-induced neutropenia and risk of infection among
                    patients with nonmyeloid malignancies.{" "}
                    <em>Support Care Cancer.</em>
                    2016;24(10):4377-4383.
                  </li>
                  <li>Data on file. Spectrum Pharmaceuticals, Inc.</li>
                </ol>
              </section>
            </div>
          </div>
        </section>
      </InViewElement>

      <section className="small-only container">
        <ReviewCallout />
      </section>
    </InViewElements>
  );
}

const pageNav = [
  {
    id: "duration-of-sn",
    label: "Duration of SN",
  },
  {
    id: "anc-profile",
    label: "ANC Profile",
  },
  {
    id: "incidence-of-fn",
    label: "Incidence of FN",
  },
  {
    id: "cycles-2to4",
    label: "Cycles 2 to 4",
  },
  {
    id: "safety-profile",
    label: "Safety Profile",
  },
];

function PageNavigation() {
  const [fixed, setFixed] = useState(false);
  function handleInView(inView, entry) {
    // Source article that inspired this technique:
    // https://css-tricks.com/styling-based-on-scroll-position/

    // Only fix once this element once the user has
    // scrolled past it.
    if (entry.boundingClientRect.y < 0) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }
  const title = "ROLVEDON® (eflapegrastim-xnst) HCP Efficacy &amp; Safety Info";
  const description =
    "View ROLVEDON® efficacy and safety information. Discover the proven noninferiority vs pegfilgrastim that could support patient care";
  const canonicalUrl = "https://www.rolvedon.com/efficacy-safety";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta
          name="keywords"
          content="eflapegrastim efficacy, rolvedon efficacy, eflapegrastim safety profile, rolvedon safety profile, eflapegrastim, eflapegrastim-xnst injection, rolvedon, rolvedon eflapegrastim-xnst injection"
        />
      </Helmet>
      <InView onChange={(inView, entry) => handleInView(inView, entry)} />
      <InViewElementsState>
        {(state) => (
          <div
            className={`page-navigation page-navigation--large ${
              fixed ? "page-navigation--fixed" : ""
            }`}
          >
            <ol>
              {pageNav.map(({ id, label }) => (
                <li key={id} className={state.active === id ? "active" : ""}>
                  <Link to={`/efficacy-safety/#${id}`}>{label}</Link>
                </li>
              ))}
            </ol>
          </div>
        )}
      </InViewElementsState>
    </>
  );
}

function PageNavigationSmall() {
  const [fixed, setFixed] = useState(false);

  function handleInView(inView, entry) {
    // Source article that inspired this technique:
    // https://css-tricks.com/styling-based-on-scroll-position/

    // Only fix once this element once the user has
    // scrolled past it.
    if (entry.boundingClientRect.y < 0) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }

  return (
    <>
      <InView onChange={(inView, entry) => handleInView(inView, entry)} />
      <InViewElementsState>
        {(state) => {
          return (
            <div
              className={`page-navigation page-navigation--small ${
                fixed ? "page-navigation--fixed" : ""
              }`}
            >
              <div className="container">
                <ol>
                  {pageNav.map(({ id, label }) => (
                    <li
                      key={id}
                      className={state.active === id ? "active" : ""}
                    >
                      <Link to={`/efficacy-safety/#${id}`}>{label}</Link>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          );
        }}
      </InViewElementsState>
    </>
  );
}

function ReviewCallout() {
  return (
    <CalloutCard
      to="/moa"
      buttonLabel="Review Structure/MOA"
      icon="moa"
      text="The first long-acting myeloid growth factor in over 20 years with a unique molecular structure"
    />
  );
}
